body {
    font-family: "Poppins", sans-serif !important;
    background-color: white;
    color: black;
}
textarea {
    background: transparent;
    width: 100%;
    height: 150px;
    color: #344675cc;
    outline: none;
    border-radius: 0.4285rem;
    resize: none;
    border-color: #344675cc;
    padding: 7px 14px;
}
.btn-nav-white {
    color: white !important;
}
.btn-nav-bg-white {
    background: #edeef033 !important;
}
.btn-nav-blue {
    color: #344675cc !important;
}
.btn-nav-bg-blue {
    background: transparent !important;
}
.navbar .btn {
    background: #edeef033;
    border-style: none;
    margin: 0 1px !important;
    padding: 10px 8px !important;
}
.btn-navbar:hover {
    background: #edeef011 !important;
    border-style: none;
    margin: 0 1px !important;
    padding: 10px 8px !important;
}

.bg-transparent {
    background: transparent !important;
}
.nav-link {
    padding: 0.5rem 13px;
    color: white !important;
}
.phones a {
    background: #344675;
    font-weight: 400;
    padding: 10px;
    color: white !important;
    margin-right: 25px;
    border-radius: 30px;
}

@media screen and (max-width: 1200px) {
    .phones, .phones a {
        display: none !important;
    }
    .phone {
        background: #344675;
        font-weight: 400;
        color: white;
        border-radius: 30px;
        margin: auto;
        width: 119px;
        padding: 10px !important;
    }
    .nav-link .phone a {
        color: white !important;
    }
    .links-mobile-scroll .phone {
        padding: 18px 10px !important;
    }
    .footer .margint20 {
        margin: 20px 0 0 40px !important;
    }

}
@media screen and (max-width: 991px) {
    .footer .links {
        display: flex !important;
        margin: 20px auto 10px;
    }/*
    .footer .nav-link {
        padding: 18px 22px !important;
    }*/
    .links, .links-scroll {
        display: none !important;
    }
    .nav-link {
        padding: 18px 22px !important;
    }
    .navbar-collapse {
        background: #edeef0;
    }
    .navbar-collapse li button, .collapse-close button i,
    .navbar .navbar-collapse .navbar-collapse-header .collapse-brand a {
        color: #344675cc !important;
    }
    .navbar .navbar-collapse .navbar-collapse-header .collapse-brand a:hover{
        color: #243475 !important;
    }
    .phones {
        display: none;
    }
    .links-mobile .phone {
        background: #344675;
        font-weight: 400;
        color: white !important;
        border-radius: 30px;
        margin: auto;
        width: 119px;
        padding: 20px 10px !important;
    }
    .footer .margint20 {
        margin: 20px 0 0 !important;
    }
    .footer .col-md-2, .footer .col-md-3, .footer .col-md-4 {
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
        max-width: 100%;
        flex: none;
        align-content: center;
        text-align: center;
    }
    .footer .nav {
        float: none;
        padding-left: 0;
    }
    .footer hr {
        margin: 0 auto !important;
    }
}
@media screen and (min-width: 1201px) {
    .phone {
        display:none;
    }
}
@media screen and (min-width: 768px) {

}
@media screen and (min-width: 991px) {
    .links-mobile, .links-mobile-scroll {
        display: none !important;
    }
}
.links-mobile, .links, .links-mobile-scroll, .links-scroll {
    display: flex;
    border-radius: 30px;
    background: #edeef033;
}
.links-mobile i, .links i, .links-mobile-scroll i, .links-scroll i {
    margin: 0;
    position: relative;
    top: 50%;
    width: 16px;
    transform: translateY(-50%);
}

.links-mobile a:hover, .links a:hover {
    border-radius: 30px;
    background: #edeef066;
}
.kbpl {
    content: url("../img/kb.png");
}
.oferteo {
    content: url("../img/oferteo.png");
}
.needhelp {
    content: url("../img/needhelp.png")
}
.links-mobile-scroll a:hover, .links-scroll a:hover {
    border-radius: 30px;
    background: #34467522;
}
.kbpl-scroll {
    content: url("../img/kbpls.png");
}
.oferteo-scroll {
    content: url("../img/oferteos.png");
}
.needhelp-scroll {
    content: url("../img/needhelps.png")
}
.justify-content-center {
    justify-content: center;
}
.page-header .carousel-caption {
    display: none !important;
}
.page-header .carousel-control-prev, .page-header .carousel-control-next {
    display: none;
}
.page-header img {
    filter: brightness(70%);
}
.page-header .carousel-two-captions {
    position: absolute;
    right: 30%;
    left: 7%;
    top: 40%;
    bottom:70%;
    text-align: left;
}
@media screen and (max-width: 768px) {
    .page-header .carousel-two-captions {
        top: 15%;
    }
}
.page-header .carousel-two-captions h3 {
    font-size: 5.0625rem;
    /*color: #344675cc;*/
    color: #edeef0;
}

.page-header p,.page-header .carousel-two-captions p {
    width: fit-content;
    font-size: 1rem;
    font-weight: bold;
    position: relative;
    background: #edeef0;
    color: #344675cc;
    padding: 10px;
    margin-bottom: 15px;
   /* -webkit-border-top-left-radius: 10px;
    -webkit-border-bottom-right-radius: 10px;
    -moz-border-radius-topleft: 10px;
    -moz-border-radius-bottomright: 10px;*/
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.page-header #caption2 {
    text-align: left;
}
span i {
    color: #344675cc;
}
form input {
    border: white;
}
.xshow {
    display: none;
}
.footer .btn-simple {
    color: white !important;
}
.footer .nav {
    padding-top: 0;
}
.footer .description-1 h4{
    color: white;
}
.btn-primary.btn-simple,.btn-primary.btn-simple:hover, .btn-primary.btn-simple:focus, .btn-primary.btn-simple:active, .btn-primary.btn-simple:not(:disabled):not(.disabled):active {
    color: #344675cc;
    border-color: #343675cc;
}
.form-check input[type="checkbox"]:checked + .form-check-sign::before, .form-check input[type="checkbox"]:checked + .form-check-sign::before {
    background-color: #34467599;
}
.form-check-label {
    color: #344675cc !important;
}
.info-title {
    color: #344675cc;
}
.bg-smoke {
    background-color: #edeef0aa;
}
.bg-white {
    background-color: #edeef0 !important;
    border-bottom: solid 5px #34467533;
}
.text-blue, .card p {
    color: #344675cc !important;
}
.text-blue-opacity {
    color: #34467533 !important;
}
.wrapper {
    background-color: #edeef0;
}
.section {
    margin-bottom: 30px;
    background-color: #edeef0;
    color: #344675cc;
}
.section p {
    color: #344675cc;
}
.mt-30 {
    margin-top: 30px;
}
.btn-navbar {
    border-style: none;
    margin: 0 10px !important;
    padding: 10px 0 !important;
}
@font-face {
    font-family: 'league';
    font-style: oblique;
    font-weight: bold;
    src: local('league'), url("../../assets/fonts/league.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
.card, .section {
    padding:0 0 70px;
}

.card {
    background-color: #edeef0;
    box-shadow: none;
}
.text-neon {
    color: #ffff;
    font-size: 22px;
    font-family: 'league', serif;
    text-shadow: 0 0 5px white, 0 0 10px rgba(255, 255, 255, 0.5), 0 0 15px rgba(255, 255, 255, 0.25), 0 0 20px #ffff, 0 0 25px #ffff, 0 0 30px #ffff, 0 0 35px #ffff, 0 0 40px #ffff;
    display: inline-block;
    transform: scaleX(0.7);
}
.text-neon.text2 {
    margin-left: -15px;
    position: relative;
   /* -webkit-animation: neon 1.5s ease-in-out infinite alternate;*/
    animation: neon 1.5s ease-in-out infinite alternate;
}
.text-neon.text3 {
    margin-left: -10px
}

@-webkit-keyframes neon {
    0% {
        text-shadow: 0 0 5px white, 0 0 10px rgba(255, 255, 255, 0.5), 0 0 15px rgba(255, 255, 255, 0.25), 0 0 20px #ffff, 0 0 35px #ffff, 0 0 40px #ffff, 0 0 50px #ffff, 0 0 75px #ffff, 0 0 85px black;
    }
    5% {
        text-shadow: none;
    }
    15% {
        text-shadow: 0 0 5px white, 0 0 10px rgba(255, 255, 255, 0.5), 0 0 15px rgba(255, 255, 255, 0.25), 0 0 20px #ffff, 0 0 35px #ffff, 0 0 40px #ffff, 0 0 50px #ffff, 0 0 75px #ffff, 0 0 85px black;
    }
    25% {
        text-shadow: 0 0 5px white, 0 0 10px rgba(255, 255, 255, 0.5), 0 0 15px rgba(255, 255, 255, 0.25), 0 0 20px #ffff, 0 0 35px #ffff, 0 0 40px #ffff, 0 0 50px #ffff, 0 0 75px #ffff, 0 0 85px black;
    }
    50% {
        text-shadow: none;
    }
    75% {
        text-shadow: 0 0 5px white, 0 0 10px rgba(255, 255, 255, 0.5), 0 0 15px rgba(255, 255, 255, 0.25), 0 0 20px #ffff, 0 0 35px #ffff, 0 0 40px #ffff, 0 0 50px #ffff, 0 0 75px #ffff, 0 0 85px black;
    }
    100% {
        text-shadow: 0 0 5px white, 0 0 15px rgba(255, 255, 255, 0.75), 0 0 20px rgba(255, 255, 255, 0.5), 0 0 30px #ffff, 0 0 45px #ffff, 0 0 50px #ffff, 0 0 60px #ffff, 0 0 85px #ffff, 0 0 95px black;
    }
}

@keyframes neon {
    0% {
        text-shadow: 0 0 5px white, 0 0 10px rgba(255, 255, 255, 0.5), 0 0 15px rgba(255, 255, 255, 0.25), 0 0 20px #ffff, 0 0 35px #ffff, 0 0 40px #ffff, 0 0 50px #ffff, 0 0 75px #ffff, 0 0 85px black;
    }
    5% {
        text-shadow: none;
    }
    15% {
        text-shadow: 0 0 5px white, 0 0 10px rgba(255, 255, 255, 0.5), 0 0 15px rgba(255, 255, 255, 0.25), 0 0 20px #ffff, 0 0 35px #ffff, 0 0 40px #ffff, 0 0 50px #ffff, 0 0 75px #ffff, 0 0 85px black;
    }
    25% {
        text-shadow: 0 0 5px white, 0 0 10px rgba(255, 255, 255, 0.5), 0 0 15px rgba(255, 255, 255, 0.25), 0 0 20px #ffff, 0 0 35px #ffff, 0 0 40px #ffff, 0 0 50px #ffff, 0 0 75px #ffff, 0 0 85px black;
    }
    50% {
        text-shadow: none;
    }
    75% {
        text-shadow: 0 0 5px white, 0 0 10px rgba(255, 255, 255, 0.5), 0 0 15px rgba(255, 255, 255, 0.25), 0 0 20px #ffff, 0 0 35px #ffff, 0 0 40px #ffff, 0 0 50px #ffff, 0 0 75px #ffff, 0 0 85px black;
    }
    100% {
        text-shadow: 0 0 5px white, 0 0 15px rgba(255, 255, 255, 0.75), 0 0 20px rgba(255, 255, 255, 0.5), 0 0 30px #ffff, 0 0 45px #ffff, 0 0 50px #ffff, 0 0 60px #ffff, 0 0 85px #ffff, 0 0 95px black;
    }
}

@media screen and (min-width: 1200px) {
    .container {
        max-width: 1336px;
    }
}

@media screen and (min-width: 768px) {
    .kontakt-form {
        padding-top: 160px;
    }
}

.scrolled {
    margin: 1.875rem 0;
    height: 80px;
    overflow-y: scroll;
    font-size: 0.7rem;
    text-align: left;
}
.input-group-prepend ,span.input-group-text, input.form-control {
    border-color: #344675cc !important;
    color: #344675cc !important;
}
.form-control {
    height: calc(2.25rem + 7px);
}
.table-responsive {
    overflow: auto;
}
h1.profile-title.text-left {
    margin: 100px 0 -145px 50px;
}
.text-left-68 {
    margin: 68px 0 -145px 50px;
}
.text-left-132 {
    margin: 132px 0 -145px 50px;
}
.display-inline {
    display: inline !important;
}
.display-inline-block {
    display: inline-block;
}
.description-1 {
    padding-left: 10px;
}
.description-2 {
    padding: 0 0 25px 43px;
}
.icon-rem-2 {
    font-size: 1.3rem;
}

.page-header .carousel.slide, .page-header .carousel-inner, .page-header .carousel-item, .page-header img {
    min-height: 100vh !important;
    min-width: 100%;
}
.page-header img {
    height: unset !important;
}
.carousel, .carousel-inner, .carousel-item {
    height: 500px !important;
}
.justify-content-space-around {
    justify-content: space-around;
}
.modal.modal-mini .modal-dialog {
  max-width: 600px;
}
.input-group-prepend .input-group-text i {
  transform: translateX(-9px);
}
.navbar {
    background-color: transparent;
}
.page-header .content-center {
    max-width: 100%;
    padding: 0;
}
.carousel .carousel-caption h3 {
    display: block;
}
.carousel, .carousel-inner, .carousel-item{
    height: 100vh;
}
.carousel-item img{
    position: relative;
    margin: 0 auto;
    top: 50%;
    transform: translate(0, -50%);
    height: 100vh;
    width: unset !important;
    max-width: unset;
}
.page-header .content-center {
    color: black;
}
.navbar-toggler {
    color: #212529;
}
.brand-logo {
    height: 3rem;
    margin: 0 10px 0 0;
}
.dropdown-toggle, .dropdown-toggle::after, .dropdown-toggle::before {
    color: #212529 !important;
}
.dropdown-menu .dropdown-item {
    color: #212529;
}
.btn, .navbar .navbar-nav > a.btn {
  border-radius: 0.1rem;
}
.navbar .btn {
  margin: 0 5px 0 10px;
}
.navbar-nav li {
  padding: 0;
}
.tim-row {
  margin-bottom: 20px;
}

.tim-white-buttons {
  background-color: #777777;
}

.typography-line {
  padding-left: 25%;
  margin-bottom: 35px;
  position: relative;
  display: block;
  width: 100%;
}

.typography-line span {
  bottom: 10px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  left: 0;
  margin-left: 20px;
  position: absolute;
  width: 260px;
  text-transform: none;
}

.tim-row {
  padding-top: 60px;
}

.tim-row h3 {
  margin-top: 0;
}

.switch {
  margin-right: 20px;
}

#navbar-full .navbar {
  border-radius: 0 !important;
  margin-bottom: 15px;
  z-index: 2;
}

#menu-dropdown .navbar {
  border-radius: 3px;
}

#pagination-row .pagination-container {
  height: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
}

#icons-row i.now-ui-icons {
  font-size: 30px;
}

.space {
  height: 130px;
  display: block;
}

.space-110 {
  height: 110px;
  display: block;
}

.space-50 {
  height: 50px;
  display: block;
}

.space-70 {
  height: 70px;
  display: block;
}

.navigation-example .img-src {
  background-attachment: scroll;
}

.navigation-example {
  background-position: center center;
  background-size: cover;
  margin-top: 0;
  min-height: 740px;
  height: 100%;
}

#notifications {
  display: block;
  width: 100%;
  position: relative;
}

.tim-note {
  text-transform: capitalize;
}

#buttons .btn,
#javascriptComponents .btn {
    margin: 0 4px 10px 0;
}

.space-100 {
  height: 100px;
  display: block;
  width: 100%;
}

.be-social {
  padding-bottom: 20px;
  /*     border-bottom: 1px solid #aaa; */
  margin: 0 auto 40px;
}

.txt-white {
  color: #FFFFFF;
}

.txt-gray {
  color: #ddd !important;
}

.parallax {
  width: 100%;
  height: 570px;
  display: block;
    background-size: cover;
    background: no-repeat fixed center center;
}

.logo-container .logo {
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #333333;
  width: 50px;
  float: left;
}

.logo-container .brand {
  font-size: 16px;
  color: #FFFFFF;
  line-height: 18px;
  float: left;
  margin-left: 10px;
  margin-top: 7px;
  width: 70px;
  height: 40px;
  text-align: left;
}

.logo-container .brand-material {
  font-size: 18px;
  margin-top: 15px;
  height: 25px;
  width: auto;
}

.logo-container .logo img {
  width: 100%;
}

.navbar-small .logo-container .brand {
  color: #333333;
}

.fixed-section {
  top: 90px;
  max-height: 80vh;
  overflow: scroll;
  position: sticky;
}

.fixed-section ul {
  padding: 0;
}

.fixed-section ul li {
  list-style: none;
}

.fixed-section li a {
  font-size: 14px;
  padding: 2px;
  display: block;
  color: #666666;
}

.fixed-section li a.active {
  color: #00bbff;
}

.fixed-section.float {
  position: fixed;
  top: 100px;
  width: 200px;
  margin-top: 0;
}

.parallax .parallax-image {
  width: 100%;
  overflow: hidden;
  position: absolute;
}

.parallax .parallax-image img {
  width: 100%;
}

@media (max-width: 768px) {
  .parallax .parallax-image {
    width: 100%;
    height: 640px;
    overflow: hidden;
  }
  .parallax .parallax-image img {
    height: 100%;
    width: auto;
  }
}

/*.separator{
    content: "Separator";
    color: #FFFFFF;
    display: block;
    width: 100%;
    padding: 20px;
}
.separator-line{
    background-color: #EEE;
    height: 1px;
    width: 100%;
    display: block;
}
.separator.separator-gray{
    background-color: #EEEEEE;
}*/

.social-buttons-demo .btn {
  margin-right: 5px;
  margin-bottom: 7px;
}

.img-container {
  width: 100%;
  overflow: hidden;
}

.img-container img {
  width: 100%;
}

.lightbox img {
  width: 100%;
}

.lightbox .modal-content {
  overflow: hidden;
}

.lightbox .modal-body {
  padding: 0;
}

@media screen and (min-width: 991px) {
  .lightbox .modal-dialog {
    width: 960px;
  }
}

@media (max-width: 991px) {
  .fixed-section.affix {
    position: relative;
    margin-bottom: 100px;
  }
}

@media (max-width: 768px) {
  .btn,
  .btn-morphing {
    margin-bottom: 10px;
  }
  .parallax .motto {
    top: 170px;
    margin-top: 0;
    font-size: 60px;
    width: 270px;
  }
}

/*       Loading dots  */

/*      transitions */

.presentation .front,
.presentation .front:after,
.presentation .front .btn,
.logo-container .logo,
.logo-container .brand {
/*  -webkit-transition: all .2s;
  -moz-transition: all .2s;
  -o-transition: all .2s;*/
  transition: all .2s;
}

#images h4 {
  margin-bottom: 30px;
}

#javascriptComponents {
  padding-bottom: 0;
}

/*      layer animation          */

.layers-container {
  display: block;
  margin-top: 50px;
  position: relative;
}

.layers-container img {
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  text-align: center;
}

.animate {
  transition: 1.5s ease-in-out;/*
  -moz-transition: 1.5s ease-in-out;
  -webkit-transition: 1.5s ease-in-out;*/
}

.navbar-default.navbar-small .logo-container .brand {
  color: #333333;
}

.navbar-transparent.navbar-small .logo-container .brand {
  color: #FFFFFF;
}

.navbar-default.navbar-small .logo-container .brand {
  color: #333333;
}

.sharing-area {
  margin-top: 80px;
}

.sharing-area .btn {
  margin: 15px 4px 0;
}

.section-thin,
.section-notifications {
  padding: 0;
}

/*.section-navbars{
    padding-top: 0;
}*/

#navbar .navbar {
  margin-bottom: 20px;
  z-index: 1 !important;
}

#navbar .navbar-toggler,
#menu-dropdown .navbar-toggler {
  pointer-events: none;
}

.section-pagination {
  padding-bottom: 0;
}

.section-pagination .pagination:last-of-type {
  margin-bottom: 7px;
}

.section-download {
  padding-top: 130px;
}

.section-download .description {
  margin-bottom: 60px;
}

.section-download h4 {
  margin-bottom: 25px;
}

.section-examples a {
  text-decoration: none;
}

.section-examples a+a {
  margin-top: 30px;
}

.section-examples h5 {
  margin-top: 30px;
}

.components-page .wrapper>.header,
.tutorial-page .wrapper>.header {
  height: 500px;
  padding-top: 128px;
  background-size: cover;
  background-position: center center;
}

.components-page .title,
.tutorial-page .title {
  color: #FFFFFF;
}

.brand .h1-seo {
  font-size: 5em;
  text-transform: uppercase;
  font-weight: 300;
}

.brand .n-logo {
  max-width: 100px;
  margin-bottom: 40px;
}

.invision-logo {
  max-width: 70px;
  top: -2px;
  position: relative;
}

.creative-tim-logo {
  max-width: 140px;
  top: -2px;
  position: relative;
}

.section-javascript .title {
  margin-bottom: 0;
}

.navbar .switch-background {
  display: block;
}

.navbar-transparent .switch-background {
  display: none;
}

.section-signup .col .btn {
  margin-top: 30px;
}

#buttons-row .btn {
  margin-bottom: 10px;
}

.section-images {
  padding-bottom: 0;
}

@media screen and (max-width: 991px) {
  .section-navbars .navbar-collapse {
    display: none !important;
  }
}

@media screen and (max-width: 576px) {
  .typography-line span {
    width: 70px;
    margin-left: 5px;
  }
}

.offline-doc .page-header:before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  content: "";
  background: rgba(0, 0, 0, .5);
}

.footer {
    background: #111;
    border: none;
}
.footer h3 {
    color: white;
    margin: 20px 0;
}
.footer hr {
    color: white;
    background: #273a61;
    height: 5px;
    margin: 0 11px 20px;
}
.hr-footer-2 {
    margin: 0 1px 20px !important;
}
.footer .margin10 {
    margin-left: 10px !important;
}
.footer .margint20 {
    margin: 20px 0 0 10px;
}
.description-3 {
    padding: 10px 0;
}
.footer .description-4 {
    margin: 20px 0 1px;
}
.footer .description-5 {
    margin: 0 0 20px;
}
.footer .footer-nav-link{
    padding: 0.5rem 13px;
}
.footer .links {
    width: fit-content;
    margin-bottom: 10px;
}
.footer hr, .footer .icon.icon-primary {
    color: #344675ff;
}